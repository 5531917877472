
import $ from 'jquery';
import Helpers from 'helpers/util';
import { Power3, TweenMax } from 'gsap';

class AnimateIn {
	constructor() {
		this.$scroll = $('.animate');

		this.clsAnimate = 'animate';
		this.clsActive = 'animate-in';

		if (!window.Modernizr.touch) {
			this.attachEvents();
		}
		else {

			for(let item of this.$scroll) {
				$(item).removeClass(this.clsAnimate);
			}

		}
	}

	attachEvents() {

		$(window).on('scroll', () => {
			this.scrollHandler();
		});

		Helpers.pubsub.subscribe('onPageReady', () => {
			this.scrollHandler('load');
		});
	}

	scrollHandler(context) {
		const scrollTop = $(window).scrollTop();
		const trigger = $(window).outerHeight() * 0.95;

		this.$scroll.each((i, v) => {
			const $currentTarget = $(v);
			const offset = $currentTarget.offset().top;

			if (context === 'load') {
				if (offset <= scrollTop + trigger) {
					$currentTarget.addClass(this.clsActive);
				}
			} else {
				if (scrollTop > offset - trigger) {
					$currentTarget.addClass(this.clsActive);
				}
			}
		});

	}
}

const _init = () => {

	new AnimateIn();

};

_init();