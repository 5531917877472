// Vendor
import $ from 'jquery';
import {TweenMax, TimelineMax, Power3, Power2 } from 'gsap';
import SplitText from './../vendor/SplitText.min.js';
import 'gsap/ScrollToPlugin';
import Component from '../classes/component';

const $body = $('body.home');

class HeroHome extends Component {

	constructor(el, options) {
		super(el, options);

		this.$el = el;
		this.$nav = $body.find('.header');

		this.$watermark = this.$el.find(this.options.watermarkSelector);
		this.$image = this.$el.find(this.options.imageSelector);
		this.$content = this.$el.find(this.options.contentSelector);
		this.$logo = this.$el.find(this.options.logoSelector);
		this.$arrow = this.$el.find(this.options.arrowSelector);
		this.$subheading = this.$el.find(this.options.subheadingSelector);
		this.$scrollArrow = this.$el.find(this.options.scrollArrowSelector);

		this.splitTitle();

		this.checkIfReady();
		this.scrollDown();

	}

	getDefaultOptions() {
		return {
			watermarkSelector : '.hero-home-text-watermark',
			imageSelector : '.hero-home-image',
			contentSelector : '.hero-home-text',
			logoSelector : '.hero-home-logo',
			arrowSelector: '.hero-home-arrow',
			subheadingSelector: '.hero-home-subheading',
			imageTbnSelector: '.hero-home-image-tbn',
			scrollArrowSelector: '.hero-home-arrow'
		};
	}

	splitTitle() {

		const $titleToSplit = this.$el.find('.priority-1');

		const titleSplit = new SplitText($titleToSplit, {
			type: 'lines',
			linesClass: 'line-title'
		});

		this.linesTitle = titleSplit.lines;

		this.animateEls();

	}

	scrollDown() {

		this.$scrollArrow.on('click',()=> {
			const pos = $(window).height() - this.$nav.outerHeight();
			TweenMax.to(window, 1, {scrollTo:{y: pos}, ease: Power3.easeInOut});
		});

	}

	checkIfReady() {

		const bg = this.$el.find(this.options.imageTbnSelector).css('background-image');
		const path = bg.replace('url(','').replace(')','').replace(/"/gi, '');

		const img = new Image();

		$(img).load(()=> {
			this.animationIn();
		});

		img.src = path;

	}

	animateEls() {

		//animation
		this.tl = new TimelineMax({
			paused: true
		});

		this.tl.add([
			TweenMax.fromTo(this.$image, 0.7, {
				xPercent: 10,
				opacity: 0,
			},{
				xPercent: 0,
				opacity: 1,
				clearProps: 'all',
				ease: Power2.easeInOut
			}),

			TweenMax.fromTo(this.$content, 0.7, {
				xPercent: -10,
				opacity: 0,
			},{
				xPercent: 0,
				opacity: 1,
				clearProps: 'all',
				ease: Power2.easeInOut
			}),

			TweenMax.fromTo(this.$nav, 0.5, {
				y: 30,
				opacity: 0
			}, {
				y: 0,
				opacity: 1,
				delay: 0.4,
				clearProps: 'y, opacity',
				ease: Power2.easeOut,
			}),

		]);

		this.tl.add([

			TweenMax.staggerFromTo(this.linesTitle, 0.8, {
				opacity: 0,
				y: 30
			},{
				y: 0,
				opacity: 1,
				ease: Power3.easeOut,
				clearProps: 'all'
			}, 0.10),

			TweenMax.fromTo(this.$subheading, 0.8, {
				opacity: 0,
				y: 30
			},{
				y: 0,
				opacity: 1,
				delay: 0.2,
				clearProps: 'all',
				ease: Power3.easeOut
			}),

			TweenMax.fromTo(this.$logo, 1, {
				opacity: 0
			}, {
				opacity: 1,
				delay: 0.4,
				clearProps: 'all'
			}),

			TweenMax.fromTo(this.$arrow, 0.5, {
				y: -20,
				opacity: 0
			}, {
				y: 0,
				opacity: 1,
				delay: 0.6,
				ease: Power2.easeOut,
				clearProps: 'all'
			}),

			TweenMax.fromTo(this.$watermark, 1, {
				xPercent: -10,
				opacity: 0
			}, {
				xPercent: 0,
				opacity: 1,
				delay: 0.8,
				clearProps: 'all',
				ease: Power2.easeOut
			})

		]);


	}

	animationIn() {
		setTimeout(()=> {
			const loadingClass = 'loading';

			if($body.hasClass(loadingClass)) {
				$body.removeClass(loadingClass);
			}

			this.tl.play();

		}, 500);
	}
}

export default HeroHome;