/**
 * Validator Form
 *
 * @author Ilenia Coppola
 * @module helpers/validator
 */


class ValidateForm {

	constructor(element, options) {

		let defaults = {
			minLength: 3,
			parentNum: 1,
			errorClass: 'has-error',
			labelClass: 'form-label',
			onSuccess() {
				console.log('DEFAULT: onSuccess() ++++');
			},
			onError() {
				console.log('DEFAULT: onError() -----');
			},
			onSubmit() {
				console.log('DEFAULT: onSubmit() ~~~~');
			}
		};

		this.element = element;
		this.settings = Object.assign({}, defaults, options);

	}

	init() {

		this.addEvents();

	}

	addEvents() {

		let submitEl = this.element.querySelector('[type="submit"]');
		let buttonEl = this.element.querySelector('[data-type="submit"]');

		if (submitEl) {
			submitEl.addEventListener('click', (e) => this.submitHandler(e), false);
		} else {
			console.warn('ValidateForm: Submit element not found.');
			buttonEl.addEventListener('click', (e) => this.submitHandler(e), false);
		}

		//input text
		let fields = this.element.querySelectorAll('input[data-required]:not([type="checkbox"]):not([type="radio"]), textarea[data-required]');
		for (let i = fields.length; i--;) {
			fields[i].addEventListener('focus', (event) => this.markField(event.target, 'restore'), false);
		}

		//checkbox
		let checboxes = this.element.querySelectorAll('input[data-required][type="checkbox"]');
		for (let i = checboxes.length; i--;) {
			checboxes[i].addEventListener('click', (event) => this.markField(event.target, 'restore'), false);
		}

		//selector
		let customSelectClass = '.js-selector[data-required]';
		let ddllist = this.element.querySelectorAll(customSelectClass);

		for (let i = ddllist.length; i--;) {
			ddllist[i].addEventListener('click', () => {
				let el = this.getClosest(event.target, customSelectClass);
				if (el.matches(customSelectClass)) {
					this.markField(el, 'restore');
				}
			}, false);
		}

		//radio button
		let customRadioClass = '.js-radio[data-required]';
		let radioGroups = this.element.querySelectorAll(customRadioClass);

		for (let i = radioGroups.length; i--;) {

			const radioEls = radioGroups[i].querySelectorAll('input[type="radio"]');

			for (let i = radioEls.length; i--;) {

				radioEls[i].addEventListener('click', () => {
					let el = this.getClosest(event.target, customRadioClass);

					if (el.matches(customRadioClass)) {
						this.markField(el, 'restore');
					}
				}, false);
			}
		}
	}

	getClosest (elem, selector) {
		for ( ; elem && elem !== document; elem = elem.parentNode ) {
			if ( elem.matches( selector ) ) {
				return elem;
			}
		}
		return null;
	}

	submitHandler(event) {

		// except the gated form

		const gatedFormClass = 'gated-form';
		if (!this.element.classList.contains(gatedFormClass)) {
			event.preventDefault();
		}

		this.settings.onSubmit();

		if (this.validate(event)) {
			this.settings.onSuccess();
		} else {
			this.settings.onError();
		}

	}

	validate() {

		let items = this.element.querySelectorAll('*[data-required]'),
			validationStatus = true;

		let constraints = {};

		for (let i = items.length; i--;) {

			let fieldStatus = true;
			let labelText = '';
			if(items[i].getAttribute('id') === 'g-recaptcha-response'){
				labelText = document.getElementById('captchaval').innerHTML;
			} else {
				labelText = this.findParentElement(items[i]).querySelector(`.${this.settings.labelClass}`).innerHTML;
			}

			switch (items[i].tagName) {

			case 'INPUT':

				if (items[i].getAttribute('type') === 'email') {
					if (!this.checkEmail(items[i].value)) {
						[fieldStatus, validationStatus] = [false, false];
						constraints.message = 'Please enter a valid email address';
					}

				} else if (items[i].getAttribute('type') === 'checkbox') {
					if (!items[i].checked) {
						[fieldStatus, validationStatus] = [false, false];
						constraints.message = 'Please confirm you agree to the privacy policy';
					}
				} else if (items[i].getAttribute('type') === 'radio') {
					if (!items[i].checked) {
						[fieldStatus, validationStatus] = [false, false];
						constraints.message = 'Please select a property type';
					}
				} else if(items[i].getAttribute('data-validation') === 'number') {
					if (!this.checkNumber(items[i].value)) {
						[fieldStatus, validationStatus] = [false, false];
						constraints.message = 'Please enter a valid mobile number';
					}
				}
				else {
					if (!this.checkText(items[i].value)) {
						[fieldStatus, validationStatus] = [false, false];
						constraints.message = `Please enter a valid ${labelText.replace(/\*/g, '')}`;
					}
				}

				break;

			case 'TEXTAREA':
				if(items[i].getAttribute('id') === 'g-recaptcha-response'){
					if(items[i].value === ''){
						[fieldStatus, validationStatus] = [false, false];
						constraints.message = 'We were not able to confirm the captcha. Please try again.';
					}
				} else {
					if (!this.checkTextarea(items[i].value)) {
						[fieldStatus, validationStatus] = [false, false];
						constraints.message = 'Please enter a message';
					}
				}

				break;

			case 'SELECT':
				if(items[i].value === '') {
					constraints.message = `Please select a ${labelText.replace(/\*/g, '')}`;
				}

				break;

			case 'DIV':
				if (items[i].classList.contains('js-selector')) {

					// custom select
					if (items[i].getAttribute('data-value') === '-' || items[i].hasAttribute('data-value') === false) {
						[fieldStatus, validationStatus] = [false, false];
						if(items[i].getAttribute('data-selecttype') === 'hear-about-us'){
							constraints.message = 'Please select an option';
						} else {
							constraints.message = `Please select a ${labelText.replace(/\*/g, '')}`;
						}
					}
				} else if (items[i].classList.contains('js-radio')) {

					// custom radio
					const radioList = items[i].querySelectorAll('input[type="radio"]');

					let radioChecked = [];
					for (let i = radioList.length; i--;) {
						if (radioList[i].checked) {
							radioChecked.push(radioList[i]);
						}
					}

					if(radioChecked.length === 0){
						[fieldStatus, validationStatus] = [false, false];
						constraints.message = 'Please select a property type';
					}

				}
				break;

			default:
				console.log(items[i].getAttribute('type'), 'Type not recognized');
				break;

			}

			// Mark field
			if (!fieldStatus) {
				this.markField(items[i], 'error');
				this.fillLabel(items[i], constraints);
			}

		}

		return validationStatus;
	}

	checkEmail(val) {
		const regExp = /[^@]+@[^@]+\.[^@]+/;
		const pattern = new RegExp(regExp);
		return pattern.test(val);
	}

	checkNumber(val) {
		// const regExp =  /\+?[1-9]{1}[0-9]{6,14}$/g;
		const regExp =  /^((\\+)|(00)|(\\*)|())[0-9]{7,14}((\\#)|())$/g;

		const pattern = new RegExp(regExp);
		return pattern.test(val);
	}

	checkText(val) {
		const regExp = /^[a-zA-Z]{2,30}$/;
		const pattern = new RegExp(regExp);
		return pattern.test(val);
	}

	fillLabel(el, obj) {
		let $errorField = '';
		if(el.getAttribute('id') === 'g-recaptcha-response'){
			  $errorField = document.getElementById("field-error-captcha");
		} else {
				$errorField = this.findParentElement(el).querySelector('.field-error');
		}
		if($errorField) {
			$errorField.innerHTML = obj.message;
		}
		return true;
	}

	markField(el, action) {
		let parent = '';
		if(el.getAttribute('id') === 'g-recaptcha-response'){
			parent = document.getElementById("captcha-wrapper");
		} else {
			parent = this.findParentElement(el);
		}
		(action === 'error') ? parent.classList.add(this.settings.errorClass) : parent.classList.remove(this.settings.errorClass);
		return true;
	}

	findParentElement(el) {
		let parent = el;

		for (let i = this.settings.parentNum; i--;) {
			parent = parent.parentElement;
		}

		return parent;
	}
}

export default ValidateForm;
