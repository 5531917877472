// Vendor
import $ from 'jquery';
import { TimelineLite, TweenLite, Power3 } from 'gsap';

// Elements
const $el = $('.header');
const $bodyHome = $('body.home');

class Navigator {
	constructor($el) {
		this.$el = $el;
		this.$burger = $el.find('.burger');
		this.$nav = $el.find('.header-nav');
		this.$close = $el.find('.header-nav-mobile-toggles-close');

		this.clsScroll = 'is-scrolling';
		this.clsActive = 'is-active';
		this.clsSticky = 'is-sticky';
		this.isAnimating = false;
		this.isActive = false;

		this.attachEvents();
	}

	attachEvents() {

		// Add opacity onscroll
		if (!$bodyHome.length) {
			this.scrollHandler();
			window.addEventListener('scroll', () => {
				this.scrollHandler();
			});
		}

		this.$burger.on('click', () => {
			this.toggleHandler();
		});

		this.$close.on('click', () => {
			this.toggleHandler();
		});
	}

	toggleHandler() {
		if (this.isAnimating) {
			return false;
		}

		this.isActive = !this.isActive;
		this.isAnimating = true;
		const xPos = (this.isActive) ? 0 : '100%';
		const tween = new TimelineLite({
			onComplete: () => {
				this.isAnimating = false;
				this.$el.toggleClass(this.clsActive);
				TweenLite.set(this.$nav, { clearProps: 'transform' });
			}
		});
		tween.to(this.$nav, 0.75, { x: xPos, ease: Power3.easeInOut });
	}

	scrollHandler() {

		const scrollPos = $(window).scrollTop();
		const $heroComponent = $('.hero');

		if($heroComponent.length > 0){
			if (scrollPos > 0) {
				this.$el.addClass(this.clsScroll);
			} else {
				this.$el.removeClass(this.clsScroll);
			}
		}
		else {
			this.$el.addClass(this.clsScroll);
		}

	}
}

const _init = () => {
	if ($el.length) {
		new Navigator($el);
	}
};

_init();