import 'babel-polyfill';

import $ from 'jquery';
import Helpers from 'helpers/util';

const resizeLayout = () => {
	Helpers.pubsub.publish('onWindowResize');
};

$(document).ready(()=> {
	Helpers.pubsub.publish('onPageReady');
});

// Window events.
$(window).on({
	resize: function() {
		if (!window.Modernizr.touch) {
			Helpers.pubsub.publish('onWindowResize');
		}
	},
	orientationchange: resizeLayout
});

// Video API//
window.onYouTubePlayerAPIReady = () => {
	Helpers.pubsub.publish('ytAPILoaded');
};

const js = document.createElement('script');
js.type = 'text/javascript';
// js.src = 'https://www.youtube.com/iframe_api';
js.src = 'https://www.youtube.com/player_api';
document.body.appendChild(js);

// window.onbeforeunload = () => {
// 	window.scrollTo(0, 0);
// };


// Pages
import './pages/homepage';
import './pages/pages';

// Components
import './components/navigator';
import './components/scrollbar-fix';
import './components/video';
import './components/media-gallery';
import './components/tools-guide';
import './components/register-form';
import './components/lightbox';
import './components/location';
import './components/animations-scroll';