import $ from 'jquery';
import { TweenLite, TimelineLite, Power3 } from 'gsap';
import Component from '../classes/component';

class HeroSlider extends Component {

	constructor(el, options) {
		super(el, options);

		this.$el = $(this.el);

		this.$itemsNav = this.$el.find(this.options.itemNavSelector);
		this.$items = this.$el.find(this.options.itemSelector);
		this.$cta = this.$el.find(this.options.ctaSelector);
		this.activeItem = 0;
		this.autoSlider = true;

		this.classes = {
			isActive  : 'is-active',
			isVisible : 'is-visible',
			isPrevious : 'is-previous'
		};

		this.attachEvents();
		this.setActive();
	}

	getDefaultOptions() {
		return {
			itemSelector : '.hero-slider-item',
			itemNavSelector : '.hero-slider-nav-item',
			itemActive : '.hero-slider-item.is-active',
			ctaSelector : '.hero-slider-cta--text',
			ctaItemSelector: '.hero-slider-item-cta',
			dataAttributeTiming: 'data-timing'
		};
	}

	toggleHandler(e) {
		e.preventDefault();

		this.$currItemNav = $(e.currentTarget);

		if(!this.$currItemNav.hasClass(this.classes.isActive)) {
			//>> id selected
			this.activeItem = this.$currItemNav.attr('data-slider');
			this.setActive();
		}
	}

	setCtaFromItem() {
		this.$cta.text(this.ctaInfo.text);
		this.$cta.parent().attr('href', this.ctaInfo.href);

	}

	getCtaFromItem(item) {
		let text = item.text();
		let href = item.attr('href');
		return { text: text, href: href };
	}

	setButton() {
		let $ctaItem = this.$currItem.find(this.options.ctaItemSelector);

		let tl = new TimelineLite({});

		//>> get info cta
		this.ctaInfo = this.getCtaFromItem($ctaItem);

		//>> set info cta with animation
		tl.to(this.$cta, 0.3, {
			y: 10,
			opacity: 0,
			ease: Power3.ease,
			onComplete: () => {
				this.setCtaFromItem();
			}
		});

		tl.to(this.$cta, 0.3, {
			y: 0,
			opacity: 1,
			ease: Power3.ease
		});
	}

	setCounter() {
		const $counter = $('.counter-text');

		let tl = new TimelineLite({});

		tl.fromTo($counter, 0.4, {
			y: 0,
			opacity: 1
		},{
			y: -20,
			opacity: 0,
			clearProps: 'all',
			ease: Power3.ease
		});

		tl.fromTo($counter, 0.3, {
			y: 20,
			opacity: 0
		},{
			y: 0,
			opacity: 1,
			clearProps: 'all',
			onStart:()=> {
				let currNum = parseInt(this.activeItem) + 1;
				$counter.empty().text(`0${currNum}`);
			},
			ease: Power3.ease
		});

	}

	setActive() {

		//navigator set activesetActive
		this.$itemsNav.removeClass(this.classes.isActive, false);
		this.$currItemNav = this.$itemsNav.filter(`[data-slider='${this.activeItem}']`);
		this.$currItemNav.addClass(this.classes.isActive, false);

		this.setCounter();

		//catch relative items
		this.$currItem = this.$items.filter(`[data-id='${this.activeItem}']`);

		//>> item to show
		this.$currItem.addClass(this.classes.isVisible, false);

		//>> item currently showed
		this.$itemActive = this.$el.find(this.options.itemActive);

		this.changeImage();
		this.setButton();
	}

	changeCurrent(prev, curr) {
		//>> set active current
		prev.removeClass(this.classes.isActive);
		curr.removeClass(this.classes.isVisible).addClass(this.classes.isActive);
	}

	changeImage(){

		const tl = new TimelineLite({
			onComplete: ()=> {

				TweenLite.set(this.$itemActive, {
					clearProps: 'opacity'
				});

				this.changeCurrent(this.$itemActive, this.$currItem);
			}
		});

		tl.to(this.$itemActive, 0.5, {
			opacity: 0,
			ease: Power3.ease
		});

	}

	nextSlide(){
		this.activeItem++;
		this.activeItem = (this.activeItem === this.$itemsNav.length) ? 0 : this.activeItem;
	}

	prevSlide(){
		this.activeItem--;
		this.activeItem = (this.activeItem === 0) ? 0 : this.activeItem;
	}

	activeAutoSlider(timing = 8000) {

		this.setTimeout = setTimeout(()=> {
			this.nextSlide();
			this.setActive();
			this.activeAutoSlider();
		}, timing);
	}

	attachEvents() {
		this.$itemsNav.on('click', (e) => {
			this.toggleHandler(e);

			if(this.autoSlider) {
				clearTimeout(this.setTimeout);
			}
		});

		// >> WITH ARROW BUTTONS activate this
		// this.$buttons.on('click', (e) => {
		// 	if($(e.currentTarget).is(this.options.buttonRightNav)) {
		// 		this.nextSlide();
		// 	}
		// 	else {
		// 		this.prevSlide();
		// 	}
		// 	this.setActive();
		// });

		if(this.autoSlider) {
			const timing =  parseInt(this.$el.attr(this.options.dataAttributeTiming));
			this.activeAutoSlider(timing);
		}

	}


	enterAnimation() {
		// >> TODO: INITIAL ENTER ANIMATION
		// const tl = new TimelineMax();
		// tl.fromTo('.hero-slider-wrapper', 1, {
		// 	scale: 0.5,
		// 	opacity: 0
		// }, {
		// 	scale: 1,
		// 	opacity: 1
		// });
	}


	init() {
		$(window).load(()=> {
			this.enterAnimation();
		});
	}

}

export default HeroSlider;