// Vendor
import $ from 'jquery';
import { TweenLite, TimelineLite, Power3 } from 'gsap';
import Hammer from 'hammerjs';

// Elements
const $el = $('[data-lightbox]');
const $body = $('body');
let $lightbox;

// Helpers
import Helpers from 'helpers/util';

// Component
import GatedForm from '../components/gated-form';

// Templates
import { gatedTemplate } from '../templates/gated-lightbox';
import { videoTemplate } from '../templates/video-lightbox';
import { carouselTemplate } from '../templates/carousel-lightbox';

function _doLayout() {
	const $div = $(`<div class="lightbox">
		<div class="lightbox-inner"></div>
	</div>`);
	$body.append($div);
	$lightbox = $div;
}

class Lightbox {
	constructor($el) {
		this.$el = $el;
		this.$lightbox = $lightbox;
		this.$lightboxInner = $lightbox.find('.lightbox-inner');

		this.lightboxType = this.$el.attr('data-lightbox'); // Carousel, Form

		// Classes and flags
		this.clsAnimating = 'is-animating';
		this.clsVisible = 'is-visible';
		this.clsActive = 'is-active';
		this.clsModalOpen = 'is-modal-opened';
		this.isVisible = false;
		this.isAnimating = false;
		this.mgIsAnimating = false; // Media Gallery is animating
		this.maxPos = 0;
		this.currentPos = 0;
		this.paginatePos = 0;
		this.videoApiLoaded = false;
		this.players = [];

		// Carousel elements
		this.close = '.lightbox .lightbox-close';
		this.arrow = '.lightbox .paginate-arrow';

		this.attachEvents();

	}

	// Used inside Carousel;
	renderYT(element) {
		const $video = $(element).find('> div');
		console.log(element);
		const player = new window.YT.Player($video.get(0), {
			height: '100%',
			width: '100%',
			videoId: element.getAttribute('data-ytid'),
			playerVars: {
				modestbranding: 1,
				rel: 0,
				showinfo: 0,
				controls: 0
			}
		});
		this.players.push(player);
	}

	renderCarousel(evt) {
		const itemCls = '.media-gallery-item';
		const currentTarget = $(evt.currentTarget).closest(itemCls);
		this.currentPos = this.$el.find(itemCls).index(currentTarget);
		this.paginatePos = parseInt($(evt.currentTarget).attr('data-id'));
		const $item = this.$el.find(itemCls);
		const images = [];

		// Image item HTML
		for (const [i, v] of Array.from($item).entries()) {
			const $img = $(v).find('img');
			const $heading = $(v).find('.media-gallery-text-heading > div');
			const $text = $(v).find('.media-gallery-text-desc-inner');
			const video = $(v).data('youtube-id');
			const $div = `<div class="lightbox-carousel-item${(i === this.currentPos) ? ` ${this.clsActive}` : ''}">

				${typeof video !== 'undefined' ? `
				<div class="lightbox-carousel-video" data-ytid="${video}">
					<div></div>
				</div>
				` : `
				<div class="lightbox-carousel-image">
					<img src="${$($img).attr('src')}" />
				</div>
				`}
				<div class="lightbox-carousel-text">
					<div>
						<div class="lightbox-carousel-text-title">
							${$heading.text()}
						</div>
						${$text.length ? $text.html() : ''}
					</div>
				</div>
			</div>`;
			images.push($div);
		}

		// Include Template
		const $html = carouselTemplate(this.paginatePos, $item, images);

		// Empty and render
		this.$lightboxInner.empty();
		this.$lightboxInner.append($html);

		// Set Variables
		this.$items = this.$lightbox.find('.lightbox-carousel-items');
		this.$item = this.$lightbox.find('.lightbox-carousel-item');
		this.$image = this.$lightbox.find('.lightbox-carousel-image');
		this.$video = this.$lightbox.find('.lightbox-carousel-video');
		this.$nav = this.$lightbox.find('.lightbox-carousel-nav');
		this.$paginateCurrent = this.$lightbox.find('.paginate-progress-current');
		this.maxPos = this.$item.length;
		this.players = [];

		// Video
		this.$video.each((i, v) => {
			this.renderYT(v);
		});

		// Do layout on next repaint
		window.setTimeout(() => {
			this.carouselDoLayout();
		}, 0);
	}

	// Used in 'Two Col Video Image Text' component
	renderVideoLightbox(evt) {
		const $element = $(evt).get(0);
		const youtubeID = $element.currentTarget.getAttribute('data-youtube-id');

		// Include Template
		const $html = videoTemplate(youtubeID);

		// Empty and render
		this.$lightboxInner.empty();
		this.$lightboxInner.append($html);

		if (youtubeID.length) {
			this.$video = this.$lightbox.find('.lightbox-video-container-inner');
			this.players = [];

			// Video
			this.$video.each((i, v) => {
				this.renderYT(v);
			});

			// Do layout on next repaint
			window.setTimeout(() => {
				this.videoDoLayout();
			}, 0);

		}
	}

	onOpen() {
		const offset = document.body.scrollTop;
		document.body.style.top = (offset * -1) + 'px';
		document.body.classList.add(this.clsModalOpen);
	}

	onClose() {
		if (document.body.classList.contains(this.clsModalOpen)) {
			const offset = parseInt(document.body.style.top, 10);
			document.body.classList.remove(this.clsModalOpen);
			document.body.scrollTop = (offset * -1);
		}
	}

	renderForm() {

		const [$beds, $bath, $cars] = [this.$el.find('.beds'), this.$el.find('.bath'), this.$el.find('.cars')];
		const [urlFile, key] = [this.$el.attr('data-pdf'), this.$el.attr('data-key')];

		// Include Template
		const $html = gatedTemplate($beds, $bath, $cars, urlFile, key);

		// Empty and render
		this.$lightboxInner.empty();

		$.when(this.$lightboxInner.append($html)).then(()=> {
			this.onOpen();

			const form = $('.gated-form').get(0);
			if(form) {
				const gatedForm = new GatedForm(form);
				gatedForm.init();
			}
		});
	}


	calculateRatio(ratio, $element) {
		let h = $element.height();
		let w = $element.width();

		let newW = -1;
		let newH = -1;

		let tmp = w / ratio;

		if (tmp > h) {
			newH = h;
			newW = h * ratio;
		} else {
			newH = w / ratio;
			newW = w;
		}

		return {
			height: newH,
			width: newW
		};
	}

	// Video-only (not the video inside Carousel)
	videoDoLayout() {
		this.$video.css({
			width: '',
			height: ''
		});

		const ratioVideo = 1.777777778;
		const videoSize = this.calculateRatio(ratioVideo, this.$video);

		this.$video.css({
			height : videoSize.height,
			width : videoSize.width
		});
	}

	carouselDoLayout() {
		const ratioImage = 1.497402597;
		const ratioVideo = 1.777777778;

		const imageSizes = this.calculateRatio(ratioImage, this.$items);
		const videoSize = this.calculateRatio(ratioVideo, this.$items);

		if (this.$image.length) {
			this.$image.css({
				height : imageSizes.height,
				width : imageSizes.width
			});
		}

		if (this.$video.length) {
			this.$video.css({
				height : videoSize.height,
				width : videoSize.width
			});
		}
	}

	attachEvents() {
		$('html').on('click', this.close, () => {
			this.closeHandler();
		});

		$('html').on('click', this.arrow, (evt) => {
			this.arrowHandler(evt);
		});

		const hamm = new Hammer(this.$lightbox.get(0), {
			velocity : 1,
			direction: Hammer.DIRECTION_VERTICAL,
			preventDefault: true
		});

		hamm.on('swipeleft', function() {
			this.direction = 'next';
			this.swipeHandler('left');
		}.bind(this));

		hamm.on('swiperight', function() {
			this.direction = 'prev';
			this.swipeHandler('right');
		}.bind(this));

		Helpers.pubsub.subscribe('onWindowResize', () => {
			if (this.isVisible) {
				if (this.lightboxType === 'carousel') {
					this.carouselDoLayout();
				} else if (this.lightboxType === 'video') {
					this.videoDoLayout();
				}
			}
		});

		Helpers.pubsub.subscribe('closeLightbox', () => {
			this.closeHandler();
		});

		Helpers.pubsub.subscribe('mediaGalleryAnimating', (i, v) => {
			this.mgIsAnimating = v;
		});

		Helpers.pubsub.subscribe('ytAPILoaded', () => {
			this.ytAPILoaded = true;
		});

		// Event handler for onclick
		Helpers.pubsub.subscribe('onLightboxCta', (i, evt) => {
			const $event = $(evt).get(0);
			const $currentTarget = $event.currentTarget;
			const $parent = $($currentTarget).closest('[data-lightbox]');

			if ($parent.length && $parent.is(this.$el)) {
				if (this.mgIsAnimating) {
					return false;
				}
				this.showHandler($event);
			}
		});
	}

	showHandler(evt) {
		if (this.lightboxType === 'carousel') {
			this.renderCarousel(evt);
		} else if (this.lightboxType === 'form') {
			this.renderForm(evt);
		} else if (this.lightboxType === 'video') {
			this.renderVideoLightbox(evt);
		}

		this.isVisible = true;
		this.$lightbox.addClass(this.clsVisible);
	}

	closeHandler() {
		this.isVisible = false;
		this.$lightbox.removeClass(this.clsVisible);
		this.pauseVideos();

		this.$video = null;
		this.$image = null;

		this.onClose();
	}

	swipeHandler(direction) {
		if (this.isAnimating) {
			return;
		}

		if (!this.isVisible) {
			return;
		}

		if (this.lightboxType !== 'carousel') {
			return;
		}

		if (direction === 'left') {
			this.direction = 'next';
			this.currentPos++;
			this.paginatePos++;
		} else {
			this.direction = 'prev';
			this.currentPos--;
			this.paginatePos--;
		}

		this.checkPos();
		this.animateBanner();
	}

	pauseVideos() {
		for (const player of this.players) {
			player.pauseVideo();
		}
	}

	arrowHandler(e) {
		if (this.isAnimating) {
			return false;
		}

		if (!this.isVisible) {
			return;
		}

		const currentTarget = $(e.currentTarget);

		if (currentTarget.hasClass('paginate-arrow-right')) {
			this.currentPos++;
			this.paginatePos++;
			this.direction = 'next';
		} else {
			this.currentPos--;
			this.paginatePos--;
			this.direction = 'prev';
		}

		this.checkPos();
		this.animateBanner();
	}

	checkPos() {
		if (this.currentPos > this.maxPos - 1) {
			this.currentPos = 0;
		}

		if (this.currentPos < 0) {
			this.currentPos = this.maxPos - 1;
		}

		// We need different variables for paginatePos & currentPos
		if (this.paginatePos > this.maxPos - 1) {
			this.paginatePos = this.paginatePos - (this.maxPos);
		}

		if (this.paginatePos < 0) {
			this.paginatePos = this.maxPos - 1;
		}
	}

	animateBanner() {
		this.pauseVideos();
		this.isAnimating = true;
		const distance = (this.direction === 'prev') ? -this.$item.eq(0).outerWidth() : this.$item.eq(0).outerWidth();

		const $target = this.$item.eq(this.currentPos);
		const paginateVal = (this.paginatePos > 8) ? this.paginatePos + 1 : `0${this.paginatePos + 1}`;
		this.$paginateCurrent.text(paginateVal);

		TweenLite.set($target, { x: distance, className: `+=${this.clsAnimating}` });

		var tween = new TimelineLite({onComplete: function() {
			TweenLite.set($target, { clearProps: 'all' });
			this.$item.removeClass(`${this.clsActive} ${this.clsAnimating}`);
			$target.addClass(this.clsActive);
			this.isAnimating = false;
		}.bind(this)});

		tween.to($target, 0.75, { x: 0, ease: Power3.easeInOut });
	}
}

const _init = () => {
	if ($el.length) {
		_doLayout();

		$('html').on('click', '.js-lightbox-cta', function (evt) {
			evt.preventDefault();
			Helpers.pubsub.publish('onLightboxCta', evt);
		});

		$el.each(function (i, v) {
			const $currentTarget = $(v);
			new Lightbox($currentTarget);
		});
	}
};

_init();
