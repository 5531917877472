// Vendor
import $ from 'jquery';
import Helpers from 'helpers/util';
import { TweenLite, TimelineLite, Power3 } from 'gsap';
import Hammer from 'hammerjs';

// Misc
const MARGIN_LEFT = 0.6316812439;
const POSITION_LEFT = 1 - MARGIN_LEFT;
const MAX_WIDTH = 900;
const BREAKPOINT = 1024;

// Elements
const $el = $('.media-gallery');

class MediaGallery {
	constructor($el) {
		this.$el = $el;
		this.$items = $el.find('.media-gallery-items');
		this.$outer = $el.find('.media-gallery-item-outer');
		this.$item = $el.find('.media-gallery-item');
		this.$text = $el.find('.media-gallery-text-outer');

		this.$paginate = $el.find('.paginate');
		this.$paginateCurrent = $el.find('.paginate-progress-current');
		this.$paginateTotal = $el.find('.paginate-progress-total');
		this.$paginateIndicator = $el.find('.paginate-indicator-bar');

		this.$arrow = $el.find('.paginate-arrow');

		this.maxWidth = MAX_WIDTH;
		this.currentPos = 0;
		this.prevPos = 0;
		this.paginatePos = this.currentPos;
		this.maxPos = this.$item.length;
		this.isAnimating = false;
		this.ratioWidth = 0;
		this.clsActive = 'is-active';
		this.clsAbs = 'is-abs';

		this.attachEvents();
		this.updateIndicator();
	}

	attachEvents() {
		this.doLayout();
		this.setMobileHeight();
		Helpers.pubsub.subscribe('onWindowResize', () => {
			this.doLayout();
			this.setMobileHeight();
		});

		this.$arrow.on('click', (e) => {
			this.handleArrowClick(e);
		});

		this.$el.on('click', this.$item.selector, (e) => {
			this.handleItemClick(e);
		});

		this.$paginateTotal.text((this.maxPos > 9) ? this.maxPos : `0${this.maxPos}`);

		const hamm = new Hammer(this.$el.get(0), {
			velocity : 1,
			direction: Hammer.DIRECTION_VERTICAL,
			preventDefault: true
		});

		hamm.on('swipeleft', function() {
			this.direction = 'next';
			this.handleSwipe('left');
		}.bind(this));

		hamm.on('swiperight', function() {
			this.direction = 'prev';
			this.handleSwipe('right');
		}.bind(this));
	}

	doLayout($el = this.$item, isResize = true) {
		const width = (this.$items.outerWidth() > this.maxWidth) ? this.maxWidth : this.$items.outerWidth();
		const w = (window.innerWidth > 1023) ? width : width * 0.9;
		this.ratioWidth = w;

		$el.each((i, v) => {
			$(v).css({
				flex: `0 0 ${w}px`,
				marginLeft: (!i && isResize) ? '' :  -(w * MARGIN_LEFT)
			});
		});
	}

	setMobileHeight() {
		this.$outer.css('marginBottom', '');
		if (window.innerWidth < 1024) {
			const maxHeight = Math.max.apply(null, this.$text.map(function () {
				return $(this).outerHeight();
			}).get());
			this.$outer.css('marginBottom', maxHeight);
		}
	}

	handleSwipe(direction) {
		if (this.isAnimating) {
			return;
		}

		this.prevPos = this.currentPos;
		if (direction === 'left') {
			this.direction = 'next';
			this.currentPos++;
			this.paginatePos++;
		} else {
			this.direction = 'prev';
			this.currentPos--;
			this.paginatePos--;
		}

		this.handleActions();
	}

	handleItemClick(e) {
		if (this.isAnimating) {
			return false;
		}

		const element = e.target;
		const parent = $(e.target).parent().get(0);

		if (element.classList.contains('js-lightbox-cta') || parent.classList.contains('js-lightbox-cta')) {
			return false;
		}

		const currentTarget = $(e.currentTarget);
		const index = this.$item.index(currentTarget);

		if (!currentTarget.hasClass(this.clsActive)) {
			this.direction = 'next';
			this.currentPos = index;
			this.paginatePos += index;

			this.handleActions();
		}
	}

	handleArrowClick(e) {
		if (this.isAnimating) {
			return false;
		}

		const currentTarget = $(e.currentTarget);

		this.prevPos = this.currentPos;

		if (currentTarget.hasClass('paginate-arrow-right')) {
			this.direction = 'next';
			this.currentPos++;
			this.paginatePos++;
		} else {
			this.direction = 'prev';
			this.currentPos--;
			this.paginatePos--;
		}

		this.handleActions();
	}

	checkPos() {
		// 'CurrentPos' will reset when animation completes.
		// Therefore, we need different logic for the pagination

		if (this.currentPos > this.maxPos - 1) {
			this.currentPos = 0;
		}

		if (this.currentPos < 0) {
			this.currentPos = this.maxPos - 1;
		}

		// We need different variables for paginatePos & currentPos
		if (this.paginatePos > this.maxPos - 1) {
			this.paginatePos = this.paginatePos - (this.maxPos);
		}

		if (this.paginatePos < 0) {
			this.paginatePos = this.maxPos - 1;
		}
	}

	handleActions() {
		this.checkPos();
		this.updatePagination();
		this.updateIndicator();
		this.animateGallery();
	}

	updatePagination() {
		const position = (this.paginatePos > 8) ? this.paginatePos + 1 : `0${this.paginatePos + 1}`;
		this.$paginateCurrent.text(position);
	}

	fadeInText($el, delay = 0) {
		const $text = $el.find('.media-gallery-text');
		if ($text.length) {
			TweenLite.fromTo($text, 0.45, { opacity: 0, ease: Power3.easeInOut }, {delay: delay, opacity: 1, clearProps: 'all' });
		}
	}

	fadeOutText($el) {
		const $text = $el.find('.media-gallery-text');
		if ($text.length) {
			TweenLite.to($text, 0.45, { opacity: 0, ease: Power3.easeInOut });
			TweenLite.set($text, { delay: 0.75, clearProps: 'opacity, transform' });
		}
	}

	expandText($el, delay = 0) {
		const $text = $el.find('.media-gallery-text-inner');
		const $heading = $el.find('.media-gallery-text-heading');
		const $desc = $el.find('.media-gallery-text-desc');

		if ($text.length) {
			let height = ($heading.length) ? $heading.outerHeight(true) : 0;
			if ($desc.length) {
				height += $desc.outerHeight();
			}
			//const height = $text.find('.media-gallery-text-desc-inner').outerHeight();
			TweenLite.fromTo($text, 0.3, {opacity: 0, height: 0}, { delay: delay, height: height, opacity: 1, clearProps: 'all' });
		}
	}

	shrinkText($el) {
		const $text = $el.find('.media-gallery-text-inner');
		if ($text.length) {
			TweenLite.to($text, 0.35, { opacity: 0, height: 0 });
			TweenLite.set($text, { delay: 0.75, clearProps: 'all' });
		}
	}

	updateIndicator() {
		const percent = ((this.paginatePos + 1) / (this.maxPos)) * 100;
		TweenLite.set(this.$paginateIndicator, { x: `${percent}%` });
	}

	animateGallery() {
		this.isAnimating = true;
		Helpers.pubsub.publish('mediaGalleryAnimating', true);

		// DOM elements
		let $last = null;
		let $prev = this.$item.eq(0);
		let $prevImg = $prev.find('.media-gallery-item-inner');
		let $next = $prev.clone().removeClass(this.clsActive);
		const $text =(this.direction === 'next') ? this.$item.eq(this.currentPos).find('.media-gallery-text') : this.$item.eq(0).find('.media-gallery-text');

		// Select items to translateX. Different between 'prev' and 'next'
		const $items = (this.direction === 'next') ? this.$item.not($prev) : this.$item;

		// Width and height
		const w = $prev.outerWidth();
		const h = $prev.outerHeight();

		// Position
		const prevX = w * POSITION_LEFT;

		// Next x amount
		const nextX = Math.floor(w * POSITION_LEFT); // Amount to move foward

		// Calculate which items need to clip (if this.$item is clicked)
		const xDifference = Math.abs(this.currentPos - this.prevPos);
		let x = (xDifference > 1) ? (nextX * xDifference) + POSITION_LEFT : nextX;
		const sliced = (xDifference > 1) ? this.$item.slice(0, this.currentPos) : false;

		// We need to duplicate first items to end
		if (sliced && this.direction === 'next') {
			$prev = sliced;
			//$prev.removeClass(this.clsActive);
			$prevImg = $prev.find('.media-gallery-item-inner');
			$next = $prev.clone().removeClass(this.clsActive);
		}

		// Get distance for each item to translateX by
		const distance = (this.direction === 'prev') ? prevX : `-${x}`;

		// If prev, we need to move last item first (and set as absolute to keep layout)
		if (this.direction === 'prev') {
			$last = this.$item.eq(this.maxPos - 1).clone();
			$last.addClass(`${this.clsAbs} ${this.clsActive}`);
			$last.prependTo(this.$items);
			TweenLite.set($last, { width: this.ratioWidth, marginLeft: '' });
		} else {
			this.doLayout($next, false);
			this.$items.append($next);
			$items.push($next);
		}

		// Animate
		const tween = new TimelineLite({
			onComplete: () => {
				this.isAnimating = false;
				if (this.direction === 'prev') {
					this.$item.eq(this.maxPos - 1).remove();
					$last.removeClass(this.clsAbs);
				} else {
					$next.remove();
					$prev.removeClass(this.clsActive);
					$prev.appendTo(this.$items);
				}
				this.$item = this.$el.find('.media-gallery-item');
				TweenLite.set([this.$item, $prevImg, $last], { clearProps: 'clip, transform, width' });
				TweenLite.set($text, { clearProps: 'all' });

				this.doLayout();

				this.currentPos = 0;
				this.nextPos = 0;

				this.$item.removeClass(this.clsActive);
				this.$item.eq(0).addClass(this.clsActive);

				if (this.direction === 'next') {
					if (window.innerWidth < BREAKPOINT) {
						this.fadeInText(this.$item.eq(0));
					} else {
						this.expandText(this.$item.eq(0));
					}
				}
				Helpers.pubsub.publish('mediaGalleryAnimating', false);
			}
		});

		// Move items (translateX L/R)
		tween.to($items, 0.75, { x: distance, ease: Power3.easeInOut }, 'animateBanner');

		if (this.direction === 'prev') {
			// If 'prev', we need to to expand the new first item (used to be last item of array)
			// This item is then position absolutely / clipped, and reset inside onComplete
			tween.fromTo($last, 0.75, { clip: `rect(0px 0px ${h}px 0px)` },{ clip: `rect(0px ${w}px ${h}px 0px)`, ease: Power3.easeInOut }, 'animateBanner');

			// Move Text right
			tween.to($text, 0.75, { left: `${MARGIN_LEFT * 100}%`, ease: Power3.easeInOut }, 'animateBanner');

			if (window.innerWidth < BREAKPOINT) {
				this.fadeInText($last, 0.75);
				this.fadeOutText($prev);
			} else {
				// Shrink width of text
				tween.to($text, 0.75, { width: `${POSITION_LEFT * 100}%`, ease: Power3.easeInOut }, 'animateBanner');

				this.shrinkText($prev);
				this.expandText($last, 0.6);
			}
		} else {
			// Otherwise, we need to clip/mask visible items of array
			tween.fromTo($prevImg, 0.75, { clip: `rect(0px ${w}px ${h}px 0px)` }, { clip: `rect(0px 0px ${h}px 0px)`, ease: Power3.easeInOut }, 'animateBanner');

			// Expand text
			tween.to($text, 0.4, { delay: 0.3, width: w, ease: Power3.easeInOut }, 'animateBanner');

			// Move Text left
			tween.to($text, 0.75, { left: 0, ease: Power3.easeInOut }, 'animateBanner');

			// On mobile, hide text
			if (window.innerWidth < BREAKPOINT) {
				this.fadeOutText($prev);
			}
		}
	}
}

const _init = () => {

	if ($el.length) {
		$el.each(function(i, v) {
			new MediaGallery($(v));
		});
	}

};
_init();
