import $ from 'jquery';
import _ from 'underscore';
import Component from '../classes/component';
import Helpers from 'helpers/util';

let $el = $('.location');

class Location extends Component {

	constructor(el, options) {
		super(el, options);

		this.el = el;
		this.$el = $(this.el);
		this.$body = $('html,body');
		this.$window = $(window);

		this.$listFilters = this.$el.find(this.options.listFiltersSelector);
		this.$labelFilterSelected = this.$el.find(this.options.labelFilterSelected);
		this.$wrapFilters = this.$el.find(this.options.wrapFiltersSelector);

		this.DEFAULT_LAT = this.$el.attr('data-point-lat');
		this.DEFAULT_LON = this.$el.attr('data-point-lon');
		this.iconMarkerRef = this.$el.attr('data-point-icon');
		this.projectId = this.$el.attr('data-projectcatid');

		this.DEFAULT_ZOOM = 15;
		this.categoryActive = this.options.categoryDefault;
		this.isOpenInfowindow = false;
		this.isLightboxOpen = false;

		this.clickAction = window.Modernizr.touch ? 'touchend' : 'click';

		this.Route = {
			start: '',
			end: ''
		};

		this.icons = {
			default: {
				color: '#000000',
				rgb  : '0, 0, 0',
				svg  : ''
			},
			RetailAndShopping: {
				color: '#005A56',
				rgb  : '0, 90, 86',
				svg  : 'icon-map-retail'
			},
			Parks: {
				color: '#94CC79',
				rgb  : '148, 204, 121',
				svg  : 'icon-map-park'
			},
			SportsAndRecreation: {
				color: '#959A61',
				rgb  : '149, 154, 97',
				svg  : 'icon-map-sports'
			},
			ArtsAndCulture: {
				color: '#E5AF86',
				rgb  : '229, 175, 134',
				svg  : 'icon-map-culture'
			},
			RestaurantsAndBars: {
				color: '#975A49',
				rgb  : '151, 90, 73',
				svg  : 'icon-map-restaurant'
			},
			Education: {
				color: '#552334',
				rgb  : '85, 35, 52',
				svg  : 'icon-map-education'
			}
		};

		this.init();

	}

	getDefaultOptions() {
		return {
			mapIdSelector: 'map',
			mapClassSlector: '.location-map',
			apiKey: 'AIzaSyAxWxmPniQ1eZ0i1EZdzdSuHBPtZ59svWQ',
			listFiltersSelector: '.location-panel-filters-list',
			labelFilterSelected: '.location-panel-filter-selected',
			wrapFiltersSelector: '.location-panel-filters-wrap',
			itemFilterClass: 'location-panel-filter-item',
			isActiveClass: 'is-active',
			isOpenClass: 'is-open',
			categoryDefault: 'all',
			pathCircle: 'M10,5L10,5c2.8,0,5,2.2,5,5l0,0c0,2.8-2.2,5-5,5l0,0c-2.8,0-5-2.2-5-5l0,0C5,7.2,7.2,5,10,5z'
		};
	}

	loadMapsFunction () {

		let dfd = $.Deferred();

		if ($('script[src^="https://maps"]').length === 0) {

			window.GoogleMapCallback =  () => {
				dfd.resolve();
			};

			document.body.appendChild(this.script);

		} else {
			dfd.resolve();
		}

		return dfd.promise();

	}

	loadApi() {

		this.script    = document.createElement('script');
		this.scriptSrc = `https://maps.googleapis.com/maps/api/js?key=${this.options.apiKey}&libraries=geometry,places`;
		this.scriptSrc += '&callback=GoogleMapCallback';
		this.script.type = 'text/javascript';
		this.script.src  = this.scriptSrc;

		$.when(this.loadMapsFunction()).then( () =>{

			this.$body.data('load-map-api', true);
			Helpers.pubsub.publish('location:loaded-api');

		});
	}

	loadItems () {

		var urlJsonCall = '/wp-json/google/v1/maps';
		// var urlJsonCall = '/wp-content/themes/toga/handlers/location.json';

		$.ajax({
			type: 'GET',
			url: urlJsonCall,
			dataType: 'json',
			async: false,
			jsonpCallback: 'locationToga',
			contentType: 'application/json',
			success: (data) => {
				this.setItemsFromApi([data.Locations]);
			},
			error: function(e) {
				console.log('error', e.message);
			}
		});

	}

	setItemsFromApi(data) {
		this.items = data[0];

		//filtrare items
		this.itemsProject = this.filterItemsByProject(this.items);

		this.markers = this.buildMarkersArray(this.itemsProject);

		// >> fit maps in bound
		// this.fitMapInBoundMakers();

		this.createListFilters();
	}

	getMapStyles (callback) {

		// >> load style map from file
		$.get( '/wp-content/themes/toga/handlers/location-style.js', ( data ) => {
			if (data) {
				const mapStyle = JSON.parse(data);
				callback(mapStyle);
			}
		});

	}

	styleMap() {

		this.getMapStyles( (stylesArray) => {

			const styledMapType = new window.google.maps.StyledMapType(stylesArray, {
				name: 'Styled'
			});

			this.map.mapTypes.set('Styled', styledMapType);
		});

	}

	getMapOptions(optionsObj) {

		return optionsObj || {

			zoom: this.DEFAULT_ZOOM,
			center: this.position,
			mapTypeControlOptions: {
				mapTypeIds: ['Styled'],
				style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
				position: window.google.maps.ControlPosition.BOTTOM_CENTER
			},
			mapTypeId: 'Styled',
			disableDefaultUI: true,
			scrollwheel: false,
			draggable: true,
			minZoom: 10,
			zoomControl: true,
			zoomControlOptions: {
				position: window.google.maps.ControlPosition.LEFT_CENTER
			},
			disableDoubleClickZoom: false
		};

	}

	reCenterMap (point, callback) {

		let panPoint = new window.google.maps.LatLng(point.lat(), point.lng());

		this.map.panTo(panPoint);

		if (typeof callback === 'function') {
			callback();
		}
	}

	fitMapInBoundMakers(){
		//center map
		const limits = new window.google.maps.LatLngBounds();

		for (let marker of this.markers) {
			limits.extend(marker.position);
		}

		this.map.fitBounds(limits);
	}

	oneStepZoom (toLevel) {
		this.map.setZoom(toLevel);
	}

	increaseMapZoom (level, currentLevel) {
		const dfd = $.Deferred();

		for (let i = currentLevel + 1; i <= level; i++) {
			setTimeout(() => {
				this.oneStepZoom(i);
			}, 125);
		}

		dfd.resolve();

		return dfd.promise();
	}

	decreaseMapZoom (level, currentLevel) {
		const dfd = $.Deferred();

		for (let i = currentLevel - 1; i >= level; i--) {
			setTimeout(() => {
				this.oneStepZoom(i);
			}, 125);
		}

		dfd.resolve();

		return dfd.promise();
	}

	bindInfoWindow (marker, map, infowindow, description) {

		window.google.maps.event.addListener(marker, 'click', () => {

			this.Route.start = marker.getPosition();

			if(marker._category !== this.categoryActive) {
				// >> if the marker is disabled >> goes back to the all filter category
				this.filterMarkersByCategory(this.options.categoryDefault);
				this.setActiveFilterCategory(this.options.categoryDefault);
				this.categoryActive = this.options.categoryDefault;
			}

			this.openInfoWindow(marker, map, infowindow, description);

			this.calculateAndDisplayRoute(this.Route.start, this.Route.end, marker.icon.fillColor);
		});

		window.google.maps.event.addListener(map, 'click', () => {
			infowindow.close();
			this.isOpenInfowindow = false;

			let currentMapZoomLevel = this.map.getZoom();
			this.decreaseMapZoom(this.DEFAULT_ZOOM, currentMapZoomLevel);

		});
	}

	setDistanceFromPoint (item) {

		let pointLocation = this.fromCoordinatesToPoint(item.Latitude, item.Longitude);
		let distanceDecimal = (window.google.maps.geometry.spherical.computeDistanceBetween(pointLocation, this.position) / 1000).toFixed(2);
		item.Distance = distanceDecimal + ' km';

	}

	openInfoWindow (marker, map, infowindow, description) {
		infowindow.setContent(description);
		infowindow.open(map, marker);
		this.isOpenInfowindow = true;
	}

	temp (item) {

		const temp =
			`<div class="info-window" data-marker="${item._id}">
				<div class="inner-info-window">
				<% if ( Image && Image.length ) { %>
				<div class="location-item-image" style="background-image: url(<%= Image %>); border-color: ${item._color}">
					<img src="<%= Image %>" style="display: none"/>
				</div>
				<% } else {%>
					<div class="location-item-image" style="background-color: ${item._color}; border-color: ${item._color}">
						<span class="icon ${item._svg}"></span>
					</div>
				<% } %>
				<div class="location-item-wrap">
					<div class="location-item-title"><%= Name %></div>
					<div class="location-item-category">
						<%= CategoryLabel %>

						<div class="location-item-distance">
							<strong> ${item.Distance} </strong>
						</div>
					</div>
				</div>

			</div>`;

		const contentString = _.template(temp)(item);

		return contentString;

	}

	buildMarkersArray (items) {

		const markersToReturn = [];

		this.bounds  = new window.google.maps.LatLngBounds();

		this.infowindow = new window.google.maps.InfoWindow({
			content: 'loading...'
		});

		// >> create icon marker for center point
		const iconMarkerRef = {
			url: this.iconMarkerRef,
			size: new window.google.maps.Size(125, 65),
			scaledSize: new window.google.maps.Size(125,65)
		};

		// >> adding center point to the pin's list
		this.localMarker = new window.google.maps.Marker({
			position: this.fromCoordinatesToPoint(this.DEFAULT_LAT, this.DEFAULT_LON),
			icon: iconMarkerRef,
			map: this.map,
			optimized: false,
			zIndex: 1
		});


		for (let i = 0; i < items.length; i++) {

			let item = items[i];

			// >> creating icon marker svg foreach item
			this.iconMarker = {
				path: this.options.pathCircle,
				fillColor: this.icons[item.Category].color,
				fillOpacity: 1,
				scale: 1.2,
				strokeColor: `rgba(${this.icons[item.Category].rgb}, 0.4)`,
				strokeWeight: 12
			};

			// >> get position coords each point
			let posMarker = this.fromCoordinatesToPoint(item.Latitude, item.Longitude);

			// >> adding icon to the list of pins
			this.localMarker = new window.google.maps.Marker({
				position: posMarker,
				icon: this.iconMarker,
				map: this.map
			});

			// >> add to bounds
			this.bounds.extend(posMarker);

			// >> adding the reference about the category to connect pins and items
			let id = _.uniqueId('marker_');

			item._id = id;
			item._color = this.icons[item.Category].color;
			item._svg = this.icons[item.Category].svg;

			this.localMarker._id = id;
			this.localMarker._name = item.Name;
			this.localMarker._category = item.Category;

			// >> adding all list to the array
			markersToReturn.push(this.localMarker);

			// >> set distance point from each marker
			this.setDistanceFromPoint(item, this.DEFAULT_LAT, this.DEFAULT_LON);

			// >> creating info window foreach item
			let infolocation = this.temp(item);

			// >> hooking the info to the pin position on map
			this.bindInfoWindow(this.localMarker, this.map, this.infowindow, infolocation);

		}

		return markersToReturn;
	}

	fromCoordinatesToPoint (lat, lon) {

		return new window.google.maps.LatLng(lat, lon);

	}

	calculateAndDisplayRoute(pointStart, pointEnd, color) {

		const selectedMode = 'WALKING';

		if(this.line) {
			this.line.setMap(null);
		}

		this.directionsService.route({
			origin: pointStart,
			destination: pointEnd,
			travelMode: window.google.maps.TravelMode[selectedMode]
		}, (response, status) => {

			if (status === window.google.maps.DirectionsStatus.OK) {

				if(this.$window.width() > 768) {
					// let currentMapZoomLevel = this.map.getZoom();
					// this.increaseMapZoom(this.DEFAULT_ZOOM + 1, currentMapZoomLevel);
				}

				let bounds = new window.google.maps.LatLngBounds();
				bounds.extend(pointStart);
				bounds.extend(pointEnd);
				this.map.panTo(bounds.getCenter());

				// >> create line
				this.createPolyline(response, color);

				let route = response.routes[0];

				for (let i = 0; i < route.legs.length; i++) {
					this.distance = route.legs[i].distance.text;
				}
			}
		});

		window.google.maps.event.addListener(this.map, 'zoom_changed', ()=> {
			const zoomChangeBoundsListener = window.google.maps.event.addListener(this.map, 'bounds_changed', function () {

				if (this.getZoom() > this.DEFAULT_ZOOM) {// Change max/min zoom here
					this.setZoom(this.DEFAULT_ZOOM);
				}
				window.google.maps.event.removeListener(zoomChangeBoundsListener);
			});
		});

	}

	createPolyline(directionResult, color) {

		// >> creating custom line route

		this.line = new window.google.maps.Polyline({
			path: directionResult.routes[0].overview_path,
			strokeColor: color,
			strokeOpacity: 0.8,
			strokeWeight: 7
		});

		this.line.setMap(this.map);

	}

	tempFilter(category, categoryLabel, color) {

		// >> creating filter category item
		const tempFilter =
		`<div class="${this.options.itemFilterClass}" data-category="${category}">
			<span class="location-panel-icon">
				<span class="icon-border" style="background: ${color}"></span>
				<svg width="10px" height="10px" viewBox="0 0 10 10">
					<g stroke="none" transform="translate(-5.000000, -5.000000)">
						<rect fill="${color}" x="5" y="5" width="10" height="10" rx="5"></rect>
					</g>
				</svg>
			</span>
			${categoryLabel}
		</div>`;

		const contentString = _.template(tempFilter);

		return contentString;
	}

	filterMarkersByCategory(category) {

		// >> filtering markers by category selected
		for (let i = 0; i < this.markers.length; i++) {
			let marker = this.markers[i];

			if(marker._category === category || category === this.options.categoryDefault) {

				// >> pin style active
				this.iconMarker.fillColor = this.icons[marker._category].color;
				this.iconMarker.strokeColor = `rgba(${this.icons[marker._category].rgb}, 0.4)`;
				this.iconMarker.strokeWeight = 12;

				marker.setIcon(this.iconMarker);
			}
			else {

				// >> pin style disabled
				this.iconMarker.fillColor = '#9B9B9B';
				this.iconMarker.fillOpacity = 1;
				this.iconMarker.strokeColor = 'rgba(155,155,155, 0.46)';
				this.iconMarker.strokeWeight = 10;

				marker.setIcon(this.iconMarker);
			}

		}

	}

	filterItemsByProject(items) {

		let itemsProject = [];

		for (let i = 0; i < items.length; i++) {
			let item = items[i];

			if (item.ProjectCatId === this.projectId) {
				itemsProject.push(item);
			}
		}

		return itemsProject;
	}

	setActiveFilterCategory(category) {

		this.$filterItems = this.$wrapFilters.find(`.${this.options.itemFilterClass}`);

		let $currFilterActive = this.$filterItems.filter(`[data-category='${category}']`);

		this.$filterItems.removeClass(this.options.isActiveClass);
		$currFilterActive.addClass(this.options.isActiveClass);

		// >> active label for mobile
		this.setCurrentFilter($currFilterActive.clone());
	}

	setCurrentFilter(item) {

		this.$labelFilterSelected.empty().append(item);

	}

	createListFilters() {

		let lookup = {};
		// let categoriesArray = [];

		// >> adding the default category "all"
		let categoryDefaultLabel = 'All';
		let itemFilters = this.tempFilter(this.options.categoryDefault, categoryDefaultLabel, this.icons.default.color);
		this.$listFilters.append(itemFilters);

		// >> set active class to default filter
		this.setActiveFilterCategory(this.options.categoryDefault);

		// >> sorting by order
		const sortedItems = this.itemsProject.sort( (a, b) => {
			return a.Order - b.Order;
		});

		for(let item of sortedItems) {
			let category =  item.Category;
			let categoryLabel =  item.CategoryLabel;

			if (!(category in lookup)) {
				lookup[category] = 1;

				let itemFilters = this.tempFilter(category, categoryLabel, item._color);
				this.$listFilters.append(itemFilters);
			}
		}

		this.$wrapFilters.find(`.${this.options.itemFilterClass}`).on('click', (e)=> {

			let $filterClicked = $(e.currentTarget);
			let categorySelected = $filterClicked.attr('data-category');

			this.setActiveFilterCategory(categorySelected);

			// >> show only the relatives pins
			this.filterMarkersByCategory(categorySelected);

			// >> restore zoom level
			let currentMapZoomLevel = this.map.getZoom();
			this.decreaseMapZoom(this.DEFAULT_ZOOM, currentMapZoomLevel);

			// >> close if infowindow is open
			this.infowindow.close();
			this.isOpenInfowindow = false;

			// >> reset line if exist
			if(this.line) {
				this.line.setMap(null);
			}

			// >> change label and open filters >> mobile
			if(this.isLightboxOpen) {
				this.isLightboxOpen = false;
				this.$wrapFilters.removeClass(this.options.isOpenClass);
			}

			this.categoryActive = categorySelected;
		});

		// >> only for mobile version
		this.$labelFilterSelected.on('click', (e) => {
			e.preventDefault();

			if(!this.isLightboxOpen) {
				this.$wrapFilters.addClass(this.options.isOpenClass);
				this.isLightboxOpen = true;
			}
		});

	}

	initDirection() {

		this.geocoder = new window.google.maps.Geocoder();
		this.directionsService = new window.google.maps.DirectionsService;
		this.directionsDisplay = new window.google.maps.DirectionsRenderer;
		this.directionsDisplay.setMap(this.map);

		this.Route.end = this.fromCoordinatesToPoint(this.DEFAULT_LAT, this.DEFAULT_LON);

	}

	initMap() {

		Helpers.pubsub.subscribe('location:loaded-api', () => {

			this.position = new window.google.maps.LatLng(this.DEFAULT_LAT, this.DEFAULT_LON);

			const elMap = this.el.querySelector(this.options.mapClassSlector);
			this.map = new window.google.maps.Map(elMap, this.getMapOptions());

			this.styleMap();
			this.loadItems();

			this.initDirection();

			if(this.$window.width() < 768) {
				this.map.setZoom(this.DEFAULT_ZOOM - 1);
			}

		});

		if (typeof this.$body.data('load-map-api') === 'undefined') {
			this.loadApi();
		}
		else {
			Helpers.pubsub.publish('location:loaded-api');
		}

	}

	init() {

		this.initMap();

		$(window).on('resize', () => {
			this.reCenterMap(this.position);
		});

	}



}

export default Location;

const _init = () => {

	if ($el.length) {
		$el.each((i, v) => {
			new Location(v);
		});
	}

};

_init();