/**
 * Base Component
 *
 * @module classes/component
 */

class Component {

	constructor(el, options = {state: {}}) {

		this.el = this.$el = typeof el === 'string' ? document.querySelector(el) : el;

		this.options = Object.assign({}, this.getDefaultOptions(), options);

	}
	getDefaultOptions() {
		return {};
	}

	destroy() {
	}

}

export default Component;