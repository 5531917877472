
//vendor
import $ from 'jquery';
import Helpers from 'helpers/util';

//Classes
import HeroSlider from '../components/hero-slider';
import HeroHome from '../components/hero-home';

//Elements
const $body = $('body.home');
const $el = $('.hero-home');

class Home {
	constructor($el) {
		this.$el = $el;
		this.$nav = $body.find('.header');

		// Variables
		this.minY = 0;
		this.clsSticky = 'is-fixed';

		this.touchDevice = window.Modernizr.touch;

		this.doLayout();

		Helpers.pubsub.subscribe('onWindowResize', () => {
			if(!this.touchDevice){
				this.doLayout();
			}
		});

		this.scrollHandler();
		window.addEventListener('scroll', () => {
			this.scrollHandler();
		});

	}

	doLayout() {
		this.$el.css('height', window.innerHeight);
		this.minY = window.innerHeight - this.$nav.outerHeight();

		if(this.touchDevice){
			this.$nav.css('top', this.minY);
		}
	}

	scrollHandler() {
		let currentY = $(window).scrollTop();

		if (currentY > this.minY) {
			this.$nav.addClass(this.clsSticky);
			if(this.touchDevice){
				this.$nav.css('top', 0);
			}
		} else {
			this.$nav.removeClass(this.clsSticky);
			if(this.touchDevice){
				this.$nav.css('top', this.minY);
			}
		}

	}

}

const _init = () => {

	const $slider = $('.hero-slider');
	if ($slider.length) {
		new HeroSlider($slider);
	}

	if ($el.length && !$slider.length) {
		new Home($el);
		new HeroHome($el);
	}

};

_init();
