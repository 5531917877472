import $ from 'jquery';
import { TweenMax, TimelineMax, Power2, Power3 } from 'gsap';
import Component from './component';

class Dropdown extends Component {

	constructor(el, options) {
		super(el, options);

		this.$el = $(this.el);
		this.$selector = $(this.options.selectedSelector);
		this.$bgSelector = $(this.options.bgSelector);

		this.createDropDownList();

		this.$items = $(this.options.ddlItemSelector);

		this.classes = {
			isActive : 'is-active',
			isFilled : 'is-filled'
		};

		this.attachEvents();
	}

	getDefaultOptions() {
		return {
			selectedSelector : '.selector-selected',
			selectedLabelSelector: '.selector-selected-label',
			bgSelector		: '.selector-bg',
			ddlSelector 	: '.selector-ddl',
			ddlItemSelector : '.selector-ddl-item',
			ddlInnerSelector: '.selector-ddl-inner',
			ddlSelectHidden : '.selector-ddl-hidden',
			dataValue		: 'data-value',
			dataDefaultText : 'data-default-text'
		};
	}

	createDropDownList() {
		const ddlSelect = this.$el.find(this.options.ddlSelectHidden).find('select');
		const ddlSelectOptions = ddlSelect.find('option');

		for(let i = 0; i < ddlSelectOptions.length; i++) {
			let option = document.createElement('div');
			option.className = 'selector-ddl-item';
			let $el = $(ddlSelectOptions[i]);

			let value = $el.val();
			value = value === '' ? '-' : value;
			let text = $el.text();

			option.setAttribute('data-value', value);
			option.setAttribute('role', 'button');
			option.setAttribute('tabindex', i);

			option.innerHTML = text;

			this.$el.find(this.options.ddlInnerSelector).append(option);
		}

	}

	openDropDownList(el) {
		this.tlOpen = new TimelineMax();
		let $elParent = $(el).parent();
		let $selectorBg = $elParent.find(this.options.bgSelector);
		let $ddlCont = $elParent.find(this.options.ddlSelector);
		let $ddlItem = $elParent.find(this.options.ddlItemSelector).not(':first-child');

		if(!$elParent.hasClass(this.classes.isActive)){
			this.tlOpen.add([
				TweenMax.to($selectorBg, 0.4, {
					autoAlpha: 1,
					display: 'block'
				}),
				TweenMax.fromTo($ddlCont, 0.4, {
					autoAlpha: 0,
					scale: 0.9,
					y: 10
				},{
					autoAlpha: 1,
					scale: 1,
					y: 0,
					delay: 0.1,
					transformOrigin: 'center center 0px',
					display: 'block',
					ease: Power3.easeInOut
				}),
				TweenMax.staggerFromTo($ddlItem, 0.5, {
					y: 10,
					opacity: 0
				},{
					y: 0,
					opacity: 1,
					delay: 0.1,
					ease: Power2.easeInOut,
					clearProps: 'all'
				}, 0.1)
			]);

			$elParent.addClass(this.classes.isActive);
		}

	}

	fillDropDownList(el) {
		let $itemClicked = $(el);
		let value = $itemClicked.attr(this.options.dataValue);
		let text = $itemClicked.text();
		let $selector = $itemClicked.parents('.selector');
		let $label = $selector.find(this.options.selectedLabelSelector);

		//set text and value
		$selector.attr(this.options.dataValue, value);
		$label.text(text);

		if($selector.attr(this.options.dataValue) !== '-') {
			//if item is selected and data-value is filled
			$selector.addClass(this.classes.isFilled);
			this.$items.removeClass(this.classes.isActive);
			$itemClicked.addClass(this.classes.isActive);
		}
		else {
			//if empty
			$selector.removeClass(this.classes.isFilled);
			this.$items.removeClass(this.classes.isActive);
			$itemClicked.addClass(this.classes.isActive);

			//default value Label
			let defaultText = $label.attr(this.options.dataDefaultText);
			$label.text(defaultText);
		}

		this.closeDropDownList($selector);
		this.changeSelectedItem($selector, value);
	}

	changeSelectedItem(selector, value) {

		const ddlSelect = selector.find('select');
		const ddlSelectOptions = ddlSelect.find('option');
		const ddlValue = value === '-' ? '' : value;

		for(let i = 0; i < ddlSelectOptions.length; i++) {
			if (ddlSelectOptions[i].value === ddlValue) {
				ddlSelectOptions[i].selected = true;
			}
		}

	}

	closeDropDownList(selector) {

		let $activeSelector = $(selector);
		let $selectorBg = $activeSelector.find(this.options.bgSelector);
		let $ddlCont = $activeSelector.find(this.options.ddlSelector);

		const tlClose = new TimelineMax({
			onComplete: () => {
				$activeSelector.removeClass(this.classes.isActive);
			}
		});

		tlClose.add([
			TweenMax.to($selectorBg, 0.4, {
				autoAlpha: 0,
				display: 'none'
			}),
			TweenMax.to($ddlCont, 0.4, {
				autoAlpha: 0,
				ease: Power3.easeInOut
			})
		]);

	}

	attachEvents() {

		this.$selector.on('click', (e) => {
			const $el = e.currentTarget;
			this.openDropDownList($el);
		});

		this.$bgSelector.on('click', (e) => {
			const $elBg = $(e.currentTarget);
			let $selector = $elBg.parents('.selector');

			console.log($selector);

			if($elBg.parent().hasClass(this.classes.isActive)) {
				this.closeDropDownList($selector);
			}
		});

		this.$items.on('click', (e) => {
			let $el = e.currentTarget;
			this.fillDropDownList($el);
		});

	}


}

export default Dropdown;