// Vendor
import $ from 'jquery';

// Classes
import Dropdown from '../classes/dropdown';
import Accordion from '../classes/accordion';

// Helpers
import ValidateForm from '../helpers/validator';

// Elements
const $el = $('.register-form');

class RegisterForm {
	constructor($el) {
		this.$el = $el;

		this.form = document.getElementById('register-form-cont');
		this.$fields = this.$el.find('.register-form-input');

		this.activeClass = 'is-active';
		this.filledClass = 'is-filled';

		this.attachEvents();
		this.validation();
	}

	onInputFocus(ev, filled) {
		if (ev.currentTarget.value.trim() !== '') {
			$(ev.target.parentNode).addClass(filled);
		}
		else {
			$(ev.target.parentNode).removeClass(filled);
		}
	}

	onInputBlur(ev, filled) {

		if (ev.currentTarget.value.trim() === '') {
			$(ev.currentTarget.parentNode).removeClass(filled);
		}
		else {
			$(ev.currentTarget.parentNode).addClass(filled);
		}

	}

	validation() {

		if (this.form) {

			this.validate = new ValidateForm(this.form, {
				parentEl: '.register-form-inner',
				parentNum: 2,
				labelClass: 'register-form-label',
				onSuccess() {
					let form = document.getElementById('register-form-cont');
					form.submit();
				}
			}).init();

		}
	}

	attachEvents() {

		for (let i = 0; i < this.$fields.length; i++){

			let input = this.$fields[i];

			if (input.value.trim() !== '') {
				$(input.parentNode).toggleClass(this.filledClass);
			}

			$(input).on('focus', (e) => {
				this.onInputFocus(e, this.filledClass);
			});

			$(input).on('blur', (e) => {
				this.onInputBlur(e, this.filledClass);
			});

		}
	}
}

export default RegisterForm;

const _init = () => {
	if ($el) {
		new RegisterForm($el);
	}

	const $dropdown = $('.js-selector');
	if ($dropdown.length) {
		$dropdown.each((i, v) => {
			new Dropdown($(v));
		});
	}

	const $accordion = $('.accordion');
	if ($accordion.length) {
		$accordion.each((i, v) => {
			new Accordion($(v));
		});
	}

};

_init();