// Vendor
import $ from 'jquery';

// Helpers
import ValidateForm from '../helpers/validator';
import Helpers from 'helpers/util';

// Classes
import Accordion from '../classes/accordion';

class GatedForm {
	constructor(el) {
		this.el = el;
		this.$el = $(el);

		this.$fields = this.$el.find('.register-form-input');
		this.cookieName = 'cookie_download';
		this.filledClass = 'is-filled';
		this.stateLoadingClass = 'state-loading';
		this.stateDoneClass = 'state-loading';
	}

	setCookie() {
		Helpers.cookie.set(this.cookieName, 'closed', 1);
	}

	checkSentForm() {

		let hasSentCookies = Helpers.cookie.get(this.cookieName) !== null;

		if (hasSentCookies) {
			this.successState(hasSentCookies);
		}

	}

	onInputFocus(ev, filled) {
		if (ev.currentTarget.value.trim() !== '') {
			$(ev.target.parentNode).addClass(filled);
		}
		else {
			$(ev.target.parentNode).removeClass(filled);
		}
	}

	onInputBlur(ev, filled) {

		if (ev.currentTarget.value.trim() === '') {
			$(ev.currentTarget.parentNode).removeClass(filled);
		}
		else {
			$(ev.currentTarget.parentNode).addClass(filled);
		}

	}

	labelsFilled() {


		for (let i = 0; i < this.$fields.length; i++){

			let input = this.$fields[i];

			if (input.value.trim() !== '') {
				$(input.parentNode).toggleClass(this.filledClass);
			}

			$(input).on('focus', (e) => {
				this.onInputFocus(e, this.filledClass);
			});

			$(input).on('blur', (e) => {
				this.onInputBlur(e, this.filledClass);
			});

		}
	}

	validation(form) {
		if (form) {
			const _postUrl = this.postUrl.bind(this);
			this.validate = new ValidateForm(form, {
				parentEl: '.gated-form-inner',
				parentNum: 2,
				labelClass: 'gated-form-label',
				onError() {
					event.preventDefault();
				},
				onSuccess() {
					const $button = $(form).find('[data-type="submit"]');
					const urlLink = $button.attr('data-url');
					$button.attr('href', urlLink);
					_postUrl(form);
				}
			}).init();
		}
	}

	postUrl(form) {

		const $form = $(form);
		const endpoint = $form.attr('data-endpoint');
		const firstname = $form.find('input[name="firstname"]').val();
		const lastname = $form.find('input[name="lastname"]').val();
		const mobilephone = $form.find('input[name="mobilephone"]').val();
		const email = encodeURIComponent($form.find('input[name="email"]').val());
		const action = $form.find('input[name="action"]').val();

		const data = `firstname=${firstname}&lastname=${lastname}&mobilephone=${mobilephone}&email=${email}&action=${action}`;

		const $submit = $form.find('.button-submit');
		$submit.addClass(this.stateLoadingClass);

		$.ajax({
			method: 'POST',
			url: endpoint,
			data: data,
			dataType: 'json'
		}).always((data) => {
			const success = data.success === undefined ? false : data.success;

			if(success) {
				$submit.removeClass(this.stateLoadingClass);
				$submit.addClass(this.stateDoneClass);

				this.successState();
				this.setCookie(this.cookieName);
			}
		});

	}

	successState(hasSentCookies) {

		const $lightboxForm = $('.lightbox-form');
		const $btnPdf  = $lightboxForm.find('.pdf-link');
		const $privacyAccordion = $lightboxForm.find('.accordion');

		if(hasSentCookies) {
			this.$el.hide();
		}
		else{
			Helpers.pubsub.publish('closeLightbox');
		}

		$btnPdf.removeClass('is-hidden');
		$privacyAccordion.addClass('is-hidden');
	}

	init() {

		//callback
		this.validation(this.el);

		this.labelsFilled();

		this.checkSentForm();

		const $accordion = $('.accordion');
		if ($accordion.length) {
			$accordion.each((i, v) => {
				new Accordion($(v));
			});
		}
	}

}

export default GatedForm;