

export const gatedTemplate = ($beds, $bath, $cars, urlFile, key) => {
	const $html =
		`<div class="lightbox-form">
			<div class="lightbox-form-outer">
				<div class="lightbox-form-inner">
					<div class="lightbox-form-container">
						<div class="lightbox-form-close lightbox-close">
							<span class="icon icon-close"></span>
						</div>

						<div class="lightbox-form-title">
							<h2 class="priority-3">Download floor plan</h2>
						</div>

						<p>Download${$beds.length ? ` ${$beds.text()} Bedroom` : ''}${$bath.length ? ` ${$bath.text()} Bathroom` : ''}${$cars.length ? ` ${$cars.text()} Parking` : ''} floor plan</p>
							
						<form accept-charset="UTF-8" class="register-form--white gated-form" method="post" data-endpoint="/wp-json/marketo/v1/register/" data-pdf="${urlFile}" data-key="${key}">
							
							<div class="register-form-inner gated-form-inner">
								<div class="register-form-field">
									<div class="field-input">
										<input name="firstname" class="register-form-input" type="text" data-required />
										<label class="register-form-label gated-form-label">First name *</label>
									</div>
									<div class="field-error">Field required</div>
								</div>

								<div class="register-form-field">
									<div class="field-input">
										<input name="lastname" class="register-form-input" type="text" data-required />
										<label class="register-form-label gated-form-label">Last name *</label>
									</div>
									<div class="field-error">Field required</div>
								</div>

								<div class="register-form-field">
									<div class="field-input">
										<input name="mobilephone" class="register-form-input" type="text" data-validation="number" data-required  />
										<label class="register-form-label gated-form-label">Mobile Number *</label>
									</div>
									<div class="field-error">Field required</div>
								</div>

								<div class="register-form-field">
									<div class="field-input">
										<input name='email' class="register-form-input" type="email" data-required />
										<label class="register-form-label gated-form-label">Email *</label>
									</div>
									<div class="field-error">Field required</div>
								</div>

								<input name='action' type="hidden" value="RuP2cQBK" />
								<a href="javascript:void(0)" class="button button-white button-submit" data-url="${urlFile}" data-type="submit" target="_blank" > 
									Download 
									<span class="line-progress">
										<span class="line-progress-inner notransition"></span>
									</span>
								</a>
							</div>
							
						</form>

						<a class="pdf-link button button-submit is-hidden" href="${urlFile}" target="_blank"> Download </a>

						<div class="accordion">

							<div class="accordion-tab">
								<div class="register-form-privacy-cta accordion-tab-heading">
									<span class="icon icon-arrow-ddl"></span> Privacy collection statement 
								</div>

								<div class="accordion-tab-content">

									<div class="accordion-tab-content-inner">
															
										<div class="register-form-privacy-text">

											<strong>TOGA Privacy Statement</strong> </br>

											<strong>By submitting this form you accept consent to the collection, processing and use of your personal information by Toga Pty Ltd and its related companies (“TOGA” or “the TOGA Group”) in accordance with the terms and conditions set out below, including TOGA’s Privacy Policy. </strong></br>
											
											Where you receive electronic marketing communications from us, you may opt out at any time by following the unsubscribe instructions provided in the communication. </br>
											
											TOGA collects personal information that is relevant to our business relationship with you, including by way of telephone conversations, internet enquiries you make, forms you submit, personal meetings or appointments. This data may include, without limitation, your name, address, telephone number and preferences. TOGA uses your personal information to enable us to provide and improve our products and services to you, including being able to provide you with requested information, as well as obtaining your feedback. We also use personal information to protect our rights and interests, the rights and interests of other TOGA clients and prospective clients and to comply with applicable legal or regulatory obligations. While you are not obligated to provide any personal information to us, if you do not provide all of the necessary personal information we may be unable to provide our products and services to you or process your request.</br>
											
											TOGA may transfer or disclose your personal information to the following parties: other companies in the TOGA Group, external service providers such as contractors, suppliers and consultants who assist TOGA in providing our products and services, regulatory bodies, government agencies and law enforcement bodies, and other parties as permitted by law. </br>
											
											When visiting a TOGA website we may also collect additional personal information about you in the form of your IP address or domain name. The use of cookies on these sites does not identify you personally and are used to monitor usage, visitation and other information to identify the pages you visited and information you are interested in to service you in the future. </br>
											
											TOGA’s Privacy Policy is available at <a href="http://www.toga.com.au/privacypolicy">www.toga.com.au/privacypolicy</a> and contains further information about the nature and use of your personal information. If you wish to make a complaint about breaches to your personal information in accordance with the Australian Privacy Principles you can do this by emailing customercare@toga.com.au. </br>

											If you no longer wish to receive information from TOGA you can advise us of your wish by contacting customercare@toga.com.au.
										</div>			
										
									</div>			
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>`;

	return $html;

};