// Vendor
import $ from 'jquery';

// Variables
const SCROLLBAR_OFFSET = 50;
let offset = 0;
let hasMeasured = false;
let scrollbarWidth = 50;

// Elements
let $el = $('.js-scrollfix');

// This class will mask the scrollbar
class ScrollbarFix {
	constructor($el) {
		this.$el = $el;
		this.isHeight= this.$el.data('scrollfix-height') === '' ? true : false;

		if (!hasMeasured) {
			this.setOffset();
		}

		this.doLayout();
	}

	// Measure the scrollbar and set the width
	setOffset() {
		// Create the measurement node
		let scrollDiv = document.createElement('div');
		scrollDiv.className = 'scrollbar-measure';
		document.body.appendChild(scrollDiv);

		// Set the scrollbar width
		scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

		// Set the offset (used for height)
		offset = (scrollbarWidth) ? SCROLLBAR_OFFSET - scrollbarWidth : 0;

		// Delete the measurement node DIV
		document.body.removeChild(scrollDiv);

		hasMeasured = true;
	}

	doLayout() {
		if (offset) {
			if (this.isHeight) {
				this.$el.find('> div').css('height', 'calc(100% - ' + offset + 'px)');
			} else {
				this.$el.find('> div').css('width', 'calc(100% - ' + offset + 'px)');
			}
		}
	}
}

export default ScrollbarFix;

const _init = () => {
	if ($el.length) {
		$el.each((e, v) => {
			new ScrollbarFix($(v));
		});
	}
};

_init();