import $ from 'jquery';
import ScrollCarousel from 'classes/scroll-carousel';

const $el = $('.js-tools-guide');
const $carousel = $el.find('.js-scroll-carousel');
const $paginate = $el.find('.paginate');

class ShowHidePaginate {
	constructor(el) {
		this.$el = $(el);
		this.$row = $el.find('.paginate-row');
		this.$item = this.$el.find('.tools-guide-item');

		this.clsHidden = 'is-hidden';

		if (this.$item.length > 1) {
			this.attachEvents();
		}
	}

	attachEvents() {
		this.toggleVisibility();
		$(window).on('resize', () => {
			this.toggleVisibility();
		});
	}

	toggleVisibility() {
		if (this.$item.length > 3) {
			this.$row.removeClass(this.clsHidden);
		} else if (this.$item.length === 3) {
			if (window.innerWidth < 1200) {
				this.$row.removeClass(this.clsHidden);
			} else {
				this.$row.addClass(this.clsHidden);
			}
		} else if (this.$item.length === 2) {
			if (window.innerWidth < 768) {
				this.$row.removeClass(this.clsHidden);
			} else {
				this.$row.addClass(this.clsHidden);
			}
		}
	}
}

const _init = () => {

	if ($el.length && $carousel.length) {
		new ScrollCarousel($carousel, $paginate);

		$el.each((i, v) => {
			new ShowHidePaginate(v);
		});
	}

};

_init();