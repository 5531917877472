
//vendor
import $ from 'jquery';
//Classes
import HeroPages from '../components/hero-page';

//Elements
const _init = () => {

	const $hero = $('.hero-page');
	if ($hero.length) {
		new HeroPages($hero);
	}

};

_init();
