// Vendor
import $ from 'jquery';
import Hammer from 'hammerjs';
import Helpers from 'helpers/util';
import { TweenLite } from 'gsap';
import 'gsap/ScrollToPlugin';

class ScrollCarousel {
	constructor($el, $paginate) {
		this.$el = $el;
		this.$outer = $el.find('.scroll-carousel-outer');
		this.$paginate = $paginate;
		this.$scroll = $el.find('> div');
		this.$containerRow = $el.find('.scroll-carousel-container > .row');
		this.$item = $el.find('.scroll-carousel-item');

		this.curDown = false;
		this.curYPos = 0;
		this.curXPos = 0;
		this.PADDING = 0;
		this.isDragging = false;

		this.doLayout();
		Helpers.pubsub.subscribe('onWindowResize', () => {
			this.doLayout();
		});

		if (!window.Modernizr.touch) {
			this.attachEvents();
			this.$el.addClass('is-draggable');
		}

		if (this.$paginate.length) {
			this.$paginateBar = this.$paginate.find('.paginate-indicator-bar');
			this.paginateEvents();
		}
	}

	doLayout() {
		const height = this.$item.eq(0).outerHeight();
		this.$el.height(height);
		this.PADDING = (window.innerWidth > 1024) ? 50 : 20;

		if (this.$paginate.length) {
			this.scrollbarLayout();
		}
	}

	attachEvents() {

		const hammertime = new Hammer(this.$scroll.get(0));

		hammertime.on('panleft panright', (ev) => {
			this.isDragging = true;
			this.$scroll.scrollLeft(this.curXPos - ev.deltaX);
		});

		this.$el.on('mousedown', () => {
			this.curDown = true;
			this.isDragging = false;
			this.curXPos = this.$scroll.scrollLeft();
		});

		this.$el.on('mouseup', () => {
			this.curDown = false;

			if (!this.isDragging) {
				const $links = this.$el.find('a');

				if($links.length) {

					$links.parent().on('click', (e) => {
						let $el = $(e.currentTarget);

						const href = $el.find('a').attr('href');
						location.href = href;
					});

				}
			}
		});
	}

	paginateEvents() {
		this.setPaginateWidths();
		Helpers.pubsub.subscribe('onWindowResize', () => {
			this.setPaginateWidths();
			this.scrollbarTrackHandler();
		});

		const $arrows = this.$paginate.find('.paginate-arrow');
		$arrows.on('click', (evt) => {
			this.paginateClickHandler(evt);
		});

		this.$outer.on('scroll', () => {
			this.scrollbarTrackHandler();
		});
	}

	getAfterPseudo() {
		return window.getComputedStyle(
			this.$containerRow[0], ':after'
		).getPropertyValue('width');
	}

	scrollbarLayout() {
		const offsetLeft = Math.floor(this.$item.eq(0).offset().left) - (this.PADDING / 2);
		const offsetRight = parseInt(this.getAfterPseudo());
		const carouselWidth = (this.$item.eq(0).outerWidth() + this.PADDING) * this.$item.length;

		this.containerWidth = (offsetLeft + this.$outer.scrollLeft()) + offsetRight + carouselWidth;
	}

	scrollbarTrackHandler() {
		const perc = this.$outer.scrollLeft() / (this.containerWidth - $(window).innerWidth());
		const xPerc = (100 * perc > 100) ? 100 : 100 * perc;
		this.$paginateBar.css('transform', `translateX(${xPerc}%)`);
	}

	setPaginateWidths() {
		this.widths = [];
		this.$item.each((i, v) => {
			const maxWidth = i * $(v).outerWidth() + (this.PADDING * i);
			this.widths.push(maxWidth);
		});
	}

	paginateClickHandler(evt) {
		const $element = $(evt.currentTarget);
		const currentX = this.$outer.scrollLeft();
		const stepW = this.$item.outerWidth() + this.PADDING;
		const direction = $element.hasClass('paginate-arrow-right') ? 'next' : 'prev';
		const scrollX = (direction === 'next') ? this.widths.find(v => v > currentX) : this.widths.find(v => v >= (currentX - stepW));
		TweenLite.to(this.$outer, 0.5, { scrollTo: { x: scrollX }});
	}


}

export default ScrollCarousel;