

export const videoTemplate = (youtubeID) => {

	const $html = `<div class="lightbox-video">
		<div class="lightbox-video-inner">
			<div class="lightbox-video-nav">
				<div class="lightbox-video-close lightbox-close">
					<span class="icon icon-close"></span>
				</div>
			</div>
			<div class="lightbox-video-container">
				<div class="lightbox-video-container-inner" data-ytid="${youtubeID}">
					<div></div>
				</div>
			</div>
		</div>
	</div>`;

	return $html;

};