// Vendor
import $ from 'jquery';
import Component from '../classes/component';


class HeroPage extends Component {

	constructor(el, options) {
		super(el, options);

		this.$el = el;

		this.lazyLoad();
	}

	getDefaultOptions() {
		return {
			imageBgHeroSelector: '.hero-page-bg',
			titleHeroSelector: '.hero-page-title'
		};
	}

	lazyLoad() {
		const bg = this.$el.find(this.options.imageBgHeroSelector).css('background-image');
		const path = bg.replace('url(','').replace(')','').replace(/"/gi, '');

		const img = new Image();

		$(img).load(()=> {
			this.$el.find(this.options.imageBgHeroSelector).removeClass('is-transparent');
		});

		img.src = path;
	}

}

export default HeroPage;