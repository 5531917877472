import $ from 'jquery';
import Helpers from 'helpers/util';
import Component from '../classes/component';

// Elements
let $el = $('.js-video-bg');

class Video extends Component {

	constructor(el, options) {
		super(el, options);

		this.$el = $(this.el);
		this.$window = $(window);

		this.classes = {
			isActive  : 'is-active'
		};

		this.init();

	}

	getDefaultOptions() {
		return {
			dataAttrId : 'data-video-id',
			dataAttrLoop: 'data-video-loop',
			dataAttrAutoplay: 'data-video-autoplay'
		};
	}

	resizeVideo() {
		const gap = 200;

		const w = this.$window.width() + gap;
		const h = this.$window.height() + gap;

		if (this.player) {
			if (w/h > 16/9){
				this.player.setSize(w, w / 16 * 9);
				this.$el.css({'left': '0px'});
			} else {
				this.player.setSize( h / 9 * 16, h);
				this.$el.css({ 'left' : - ( this.$el.outerWidth() - w ) / 2 });
			}
		}
	}

	init() {

		this.elId = this.$el.get(0);

		this.vidId = this.$el.attr(this.options.dataAttrId);
		this.vidLoop = this.$el.attr(this.options.dataAttrLoop) === 'true' ? 1 : 0;
		this.vidAutoplay = this.$el.attr(this.options.dataAttrLoop) === 'true' ? 1 : 0;


		this.$window.on('load resize', ()=> {
			this.resizeVideo();
		});

		Helpers.pubsub.subscribe('ytAPILoaded', () => {
			const onPlayerReady = (e) => {
				e.target.mute();
				e.target.setVolume(50);
				$(e.target.a).addClass(this.classes.isActive);
			};

			this.player = new window.YT.Player(this.elId, {
				height: '100%',
				width: '100%',
				videoId: this.vidId,
				playerVars: {
					showinfo: 0,
					autoplay: this.vidAutoplay,
					controls: 0,
					modestbranding: 1,
					rel: 0,
					loop: this.vidLoop,
					enablejsapi: 1,
					playlist: this.vidId // necessary to the loop
				},
				events: {
					'onReady': onPlayerReady
				}
			});
		});

	}

}


const _init = () => {

	if ($el.length) {
		$el.each((i, v) => {
			new Video($(v));
		});
	}
};

_init();
