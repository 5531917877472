
export const carouselTemplate = (paginatePos, $item, images) => {

	// Lightbox carousel container HTML
	const $html = `<div class="lightbox-carousel">
		<div class="lightbox-carousel-nav">
			<div class="paginate">
				<div class="paginate-arrows">
					<div class="paginate-arrow-left paginate-arrow white-hover">
						<span class="icon icon-arrow"></span>
					</div>
					<div class="paginate-arrow-right paginate-arrow white-hover">
						<span class="icon icon-arrow"></span>
					</div>
				</div>
				<div class="paginate-progress">
					<span class="paginate-progress-current">${(paginatePos > 8) ? paginatePos + 1 : `0${paginatePos + 1}`}</span>
					<span class="paginate-progress-slash">/</span>
					<span class="paginate-progress-total">${($item.length > 8) ? $item.length : `0${$item.length}`}</span>
				</div>
				<div class="paginate-close lightbox-close">
					<span class="icon icon-close"></span>
				</div>
			</div>
		</div>
		<div class="lightbox-carousel-items">
			${images.join(' ')}
		</div>
	</div>`;

	return $html;

};