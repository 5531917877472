// Vendor
import 'jquery';
import { TweenMax, Expo } from 'gsap';

class Accordion {
	constructor($el) {
		this.$el = $el;
		this.$tab     = $el.find('.accordion-tab'              );
		this.$heading = $el.find('.accordion-tab-heading'      );
		this.$content = $el.find('.accordion-tab-content'      );
		this.$inner   = $el.find('.accordion-tab-content-inner');

		this.clsActive = 'is-active';

		this.attachHandlers();
	}

	attachHandlers() {
		this.$heading.on('click', () => {
			this.onAction();
		});
	}

	onAction() {
		var height = this.$inner.outerHeight(true);

		if (this.$tab.hasClass(this.clsActive)) {
			TweenMax.set(this.$content, { height: height });
			TweenMax.to(this.$content, 0.5, { height: 0, clearProps:'all', ease: Expo.easeInOut, onComplete: () => {
				this.$tab.removeClass(this.clsActive);
			}});
		} else {

			TweenMax.set(this.$content, { height: 0 });
			this.$tab.addClass(this.clsActive);
			TweenMax.to(this.$content, 0.5, { height: height, ease: Expo.easeInOut, clearProps:'all' });
		}
	}
}

export default Accordion;